import React from 'react'
import "./ourBrandsMainBanner.css";
import Image from "../../images/ourBrandsMainBanner.png";

export default function OurBrandsMainBanner() {
  return (
    <div>
        <div style={{ position: "relative" }}>
          <img className="ourBrands-Main-home-image" alt="" src={Image} />
          <div className="ourBrands-Main-image-text-box">
            <div className="ourBrands-Main-image-background-text">
              <h1 className="ourBrands-Main-image-topic">Our Brands</h1>
            </div>
          </div>
        </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import './ourBrandsSeeds.css';
import LogoImage from "../../images/CICSeeds.png";
import Seeds from "../../images/ourBrandsSeeds.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsSeeds() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="ourBrandSeeds-home">
      <center>
        <div className="ourBrandSeeds-page-container">
          <div className="ourBrandSeeds-column ourBrandSeeds-text-column">
            <div className='ourBrandSeeds-logo' data-aos="fade-right" data-aos-duration="700">
              <img className="ourBrandSeeds-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandSeeds-h1" data-aos="fade-right" data-aos-duration="500">
              Seeds
            </h2>
            <p className="ourBrandSeeds-p1" data-aos="fade-right" data-aos-duration="400">
                Different seed varieties for paddy, vegetables and fruits.
            </p>
          </div>
          <div className="ourBrandSeeds-column ourBrandSeeds-image-columns">
              <div className="ourBrandSeeds-image-container" data-aos="fade-left" data-aos-duration="700">
                <img className="ourBrandSeeds-image2" alt="seeds" src={Seeds} />
              </div>
          </div>
        </div>
      </center>
    </div>
  );
}

import React from "react";
import OurBrandsMedicalDevices from "../templates/OurBrands/ourBrandsMedicalDevices";
import OurBrandsDairy from "../templates/OurBrands/ourBrandsDairy";
import OurBrandsFreshProduce from "../templates/OurBrands/ourBrandsFreshProduce";
import OurBrandsMainBanner from "../templates/OurBrands/ourBrandsMainBanner";
import OurBrandsPlantNutrition from "../templates/OurBrands/ourBrandsPlantNutrition";
import OurBrandsPlantProtection from "../templates/OurBrands/ourBrandsPlantProtection";
import OurBrandsRice from "../templates/OurBrands/ourBrandsRice";
import OurBrandsSeeds from "../templates/OurBrands/ourBrandsSeeds";
import OurBrandsVetcare from "../templates/OurBrands/ourBrandsVetcare";
import OurBrandsPersonalCare from "../templates/OurBrands/ourBrandsPersonalCare";
import OurBrandsIndustrial from "../templates/OurBrands/ourBrandsIndustrial";
import OurBrandsDocs from "../templates/OurBrands/ourBrandsDocs";
import OurBrandsFeeds from "../templates/OurBrands/ourBrandsFeeds";
import OurBrandsPharmaceuticals from "../templates/OurBrands/ourBrandsPharmaceuticals";
import OurBrandsPoultry from "../templates/OurBrands/ourBrandsPoultry";

export default function OurBrands() {
  return (
    <div>
      <OurBrandsMainBanner />
      <OurBrandsPlantNutrition />
      <OurBrandsPlantProtection />
      <OurBrandsSeeds />
      <OurBrandsRice />
      <OurBrandsDairy />
      <OurBrandsFreshProduce />
      <OurBrandsVetcare />
      <OurBrandsMedicalDevices />
      <OurBrandsPersonalCare />
      <OurBrandsIndustrial />
      <OurBrandsDocs />
      <OurBrandsFeeds />
      <OurBrandsPharmaceuticals />
      <OurBrandsPoultry />
    </div>
  );
}

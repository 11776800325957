import React, { useEffect } from 'react';
import './ourBrandsPersonalCare.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import PersonalCare from "../../images/ourBrandsPersonalCare.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsPersonalCare() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="ourBrandPersonalCare-home">
      <center>
        <div className="ourBrandPersonalCare-page-container">
          <div className="ourBrandPersonalCare-column ourBrandPersonalCare-text-column">
            <div className='ourBrandPersonalCare-logo' data-aos="fade-right" data-aos-duration="700">
              <img className="ourBrandPersonalCare-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandPersonalCare-h1"  data-aos="fade-right" data-aos-duration="500">
              Personal Care
            </h2>
            <p className="ourBrandPersonalCare-p1"  data-aos="fade-right" data-aos-duration="400">
                Manufacture and supply of herbal healthcare and personal care products to the 
                local market and selected overseas markets.
            </p>
          </div>
          <div className="ourBrandPersonalCare-column ourBrandPersonalCare-image-columns">
              <div className="ourBrandPersonalCare-image-container" data-aos="zoom-in" data-aos-duration="700">
                <img className="ourBrandPersonalCare-image2" alt="PersonalCare" src={PersonalCare} />
              </div>
          </div>
        </div>
      </center>
    </div>
  );
}

import React, { useEffect } from 'react';
import './ourBrandsMedicalDevices.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import Baxter from "../../images/Baxter.png";
import DePuySynthes from "../../images/DePuySynthes.png";
import Dynek from "../../images/Dynek.png";
import E_life from "../../images/E_life.png";
import Molnlycke from "../../images/Molnlycke.png";
import QuidelOrtho from "../../images/QuidelOrtho.png";
import Rudolf from "../../images/Rudolf.png";
import SmithNephew from "../../images/SmithNephew.png";
import Togu from "../../images/Togu.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsMedicalDevices() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const brands = [
    { imgSrc: Baxter },
    { imgSrc: DePuySynthes },
    { imgSrc: Dynek },
    { imgSrc: E_life },
    { imgSrc: Molnlycke },
    { imgSrc: QuidelOrtho },
    { imgSrc: Rudolf },
    { imgSrc: SmithNephew },
    { imgSrc: Togu},
  ];

  return (
    <div className="ourBrandsMedicalDevices-home">
      <center>
        <div className="ourBrandsMedicalDevices-page-container">
          <div className="ourBrandsMedicalDevices-column ourBrandsMedicalDevices-image-columns">
            {brands.map((brand, index) => (
              <div key={index} className="ourBrandsMedicalDevices-image-container" data-aos="zoom-in" data-aos-duration="700">
                <img className="ourBrandsMedicalDevices-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
              </div>
            ))}
          </div>
          <div className="ourBrandsMedicalDevices-column ourBrandsMedicalDevices-text-column">
            <div className='ourBrandsMedicalDevices-logo' data-aos="fade-left" data-aos-duration="700">
              <img className="ourBrandsMedicalDevices-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandsMedicalDevices-h1" data-aos="fade-left" data-aos-duration="500">
              Medical Devices
            </h2>
            <p className="ourBrandsMedicalDevices-p1" data-aos="fade-left" data-aos-duration="400">
                Import and supply of surgical implants and other medical devices to support the 
                Country’s state sector and private sector healthcare systems.
            </p>
          </div>
        </div>
      </center>
    </div>
  );
}

import React, { useEffect } from 'react';
import './ourBrandsFeeds.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import Feeds from "../../images/feeds.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsFeeds() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="ourBrandFeeds-home">
      <center>
        <div className="ourBrandFeeds-page-container">
          <div className="ourBrandFeeds-column ourBrandFeeds-image-columns">
              <div className="ourBrandFeeds-image-container" data-aos="fade-right" data-aos-duration="700">
                <img className="ourBrandFeeds-image2" alt="Feeds" src={Feeds} />
              </div>
          </div>
          <div className="ourBrandFeeds-column ourBrandFeeds-text-column">
            <div className='ourBrandFeeds-logo' data-aos="fade-left" data-aos-duration="700">
              <img className="ourBrandFeeds-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandFeeds-h1" data-aos="fade-left" data-aos-duration="500">
              Feeds
            </h2>
            <p className="ourBrandFeeds-p1" data-aos="fade-left" data-aos-duration="400">
                Manufacture and supply of herbal healthcare and personal care products to the 
                local market and selected overseas markets.
            </p>
          </div>
        </div>
      </center>
    </div>
  );
}

import React, { useEffect } from 'react';
import './ourBrandsPharmaceuticals.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import Baxter from "../../images/Baxter.png";
import Abbott from "../../images/Abbott.png";
import ACI from "../../images/ACI.png";
import CCL from "../../images/CCL.png";
import Ensure from "../../images/Ensure.png";
import Lupin from "../../images/Lupin.png";
import Pharmevo from "../../images/Pharmevo.png";
import Platinum from "../../images/Platinum.png";
import Square from "../../images/Square.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsPharmaceuticals() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const brands = [
    { imgSrc: Baxter },
    { imgSrc: Abbott },
    { imgSrc: ACI },
    { imgSrc: CCL },
    { imgSrc: Ensure },
    { imgSrc: Lupin },
    { imgSrc: Pharmevo },
    { imgSrc: Platinum },
    { imgSrc: Square},
  ];

  return (
    <div className="ourBrandsPharmaceuticals-home">
      <center>
        <div className="ourBrandsPharmaceuticals-page-container">
          <div className="ourBrandsPharmaceuticals-column ourBrandsPharmaceuticals-text-column">
            <div className='ourBrandsPharmaceuticals-logo' data-aos="fade-right" data-aos-duration="700">
              <img className="ourBrandsPharmaceuticals-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandsPharmaceuticals-h1" data-aos="fade-right" data-aos-duration="500">
              Pharma
            </h2>
            <p className="ourBrandsPharmaceuticals-p1" data-aos="fade-right" data-aos-duration="400">
              Import and manufacture of pharmaceuticals to meet the needs of 
              the Country’s healthcare system.
            </p>
          </div>
          <div className="ourBrandsPharmaceuticals-column ourBrandsPharmaceuticals-image-columns">
            {brands.map((brand, index) => (
              <div key={index} className="ourBrandsPharmaceuticals-image-container" data-aos="zoom-in" data-aos-duration="800">
                <img className="ourBrandsPharmaceuticals-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
              </div>
            ))}
          </div>
        </div>
      </center>
    </div>
  );
}

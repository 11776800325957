import React, { useEffect } from 'react';
import './ourBrandsPlantNutrition.css';
import LogoImage from "../../images/CICFertilizer.png";
import GrowMore from "../../images/Img2.png";
import CropMaster from "../../images/Img3.png";
import GreenPro from "../../images/Img4.png";
import LifeLine from "../../images/Img5.png";
import Sakata from "../../images/Img6.png";
import Syngenta from "../../images/Img7.png";
import Yara from "../../images/Img8.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsPlantNutrition() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const brands = [
    { imgSrc: GrowMore },
    { imgSrc: CropMaster },
    { imgSrc: GreenPro },
    { imgSrc: LifeLine },
    { imgSrc: Sakata },
    { imgSrc: Syngenta },
    { imgSrc: Yara }
  ];

  return (
    <div className="ourBrandPlantNutrition-home">
      <center>
        <div className="ourBrandPlantNutrition-page-container">
          <div className="ourBrandPlantNutrition-column ourBrandPlantNutrition-text-column">
            <div className='ourBrandPlantNutrition-logo' data-aos="fade-right" data-aos-duration="700">
              <img className="ourBrandPlantNutrition-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandPlantNutrition-h1" data-aos="fade-right" data-aos-duration="500">
              Plant Nutrition
            </h2>
            <p className="ourBrandPlantNutrition-p1" data-aos="fade-right" data-aos-duration="400">
              Soil enrichment solutions to boost agricultural output. 
              We represent the global brands.
            </p>
          </div>
          <div className="ourBrandPlantNutrition-column ourBrandPlantNutrition-image-columns">
            {brands.map((brand, index) => (
              <div key={index} className="ourBrandPlantNutrition-image-container" data-aos="zoom-in" data-aos-duration="800">
                <img className="ourBrandPlantNutrition-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
              </div>
            ))}
          </div>
        </div>
      </center>
    </div>
  );
}

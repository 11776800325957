import React, { useEffect } from 'react'
import './ourBrandsRice.css';
import LogoImage from "../../images/CICAgriProduce.png";
import Rice from "../../images/ourBrandsRice.png";
import WindsorPark from "../../images/WindsorPark.png";
import GoldenCrop from "../../images/GoldenCrop.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsRice() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const brands = [
        { imgSrc: WindsorPark },
        { imgSrc: GoldenCrop },
    ];
    
  return (
    <div className="ourBrandRice-home">
      <center>
        <div className="ourBrandRice-page-container">

            <div className="ourBrandRice-column ourBrandRice-image-columns">
                {/* Image grid */}
                <div className="ourBrandRice-image-grid">
                    {brands.map((brand, index) => (
                        <div key={index} className="ourBrandRice-image-container" data-aos="zoom-in" data-aos-duration="700">
                            <img className="ourBrandRice-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
                        </div>
                    ))}
                </div>
                
                {/* Full-width rice image */}
                <div className="ourBrandRice1-image-container" data-aos="fade-right" data-aos-duration="700">
                    <img className="ourBrandRice1-image2" alt="Rice1" src={Rice} />
                </div>
            </div>

            <div className="ourBrandRice-column ourBrandRice-text-column">
                <div className='ourBrandRice-logo' data-aos="fade-left" data-aos-duration="700">
                    <img className="ourBrandRice-logo-image" alt="Logo" src={LogoImage} />
                </div>
                <h2 className="ourBrandRice-h1" data-aos="fade-left" data-aos-duration="500">
                    Rice
                </h2>
                <p className="ourBrandRice-p1" data-aos="fade-left" data-aos-duration="400">
                    A wide range of rice varieties from nutritionally enriched 
                    Health Rice and authentic Sri Lankan Traditional Rice to highly 
                    palatable Specialty Rice.
                </p>
            </div>
        </div>
      </center>
    </div>
  )
}

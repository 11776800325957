import React, { useEffect } from 'react';
import './ourBrandsVetcare.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import Frontline from "../../images/ourBrandsFrontline.png";
import Nexgard from "../../images/ourBrandsNexgard.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsVetcare() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const brands = [
    { imgSrc: Frontline },
    { imgSrc: Nexgard },
  ];

  return (
    <div className="ourBrandVetcare-home">
      <center>
        <div className="ourBrandVetcare-page-container">
          <div className="ourBrandVetcare-column ourBrandVetcare-text-column">
            <div className='ourBrandVetcare-logo' data-aos="fade-right" data-aos-duration="700">
              <img className="ourBrandVetcare-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandVetcare-h1" data-aos="fade-right" data-aos-duration="500">
              Vetcare
            </h2>
            <p className="ourBrandVetcare-p1" data-aos="fade-right" data-aos-duration="400">
                The premier specialist for the supply of internationally acclaimed vet care 
                products for the poultry industry as well as a line of pet care products.
            </p>
          </div>
          <div className="ourBrandVetcare-column ourBrandVetcare-image-columns">
            {brands.map((brand, index) => (
              <div key={index} className="ourBrandVetcare-image-container" data-aos="zoom-in" data-aos-duration="700">
                <img className="ourBrandVetcare-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
              </div>
            ))}
          </div>
        </div>
      </center>
    </div>
  );
}

import React, { useEffect } from 'react';
import './ourBrandsDairy.css';
import LogoImage from "../../images/CICAgriBusinesses.png";
import Dairy from "../../images/ourBrandsDairy.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsDairy() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="ourBrandDairy-home">
      <center>
        <div className="ourBrandDairy-page-container">
          <div className="ourBrandDairy-column ourBrandDairy-text-column">
            <div className='ourBrandDairy-logo' data-aos="fade-right" data-aos-duration="700">
              <img className="ourBrandDairy-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandDairy-h1" data-aos="fade-right" data-aos-duration="500">
              Dairy
            </h2>
            <p className="ourBrandDairy-p1" data-aos="fade-right" data-aos-duration="400">
                A range of high quality cultured dairy products and fresh milk 
                for the mainstream consumer market marketed under the iconic "CIC Creamoo label".
            </p>
          </div>
          <div className="ourBrandDairy-column ourBrandDairy-image-columns">
              <div className="ourBrandDairy-image-container" data-aos="fade-left" data-aos-duration="700">
                <img className="ourBrandDairy-image2" alt="Dairy" src={Dairy} />
              </div>
          </div>
        </div>
      </center>
    </div>
  );
}

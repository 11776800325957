import React, { useEffect } from 'react';
import './ourBrandsPoultry.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import Poultry from "../../images/ourBrandsPoultry.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsPoultry() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="ourBrandPoultry-home">
      <center>
        <div className="ourBrandPoultry-page-container">
          <div className="ourBrandPoultry-column ourBrandPoultry-image-columns">
              <div className="ourBrandPoultry-image-container" data-aos="fade-right" data-aos-duration="700">
                <img className="ourBrandPoultry-image2" alt="Poultry" src={Poultry} />
              </div>
          </div>
          <div className="ourBrandPoultry-column ourBrandPoultry-text-column">
            <div className='ourBrandPoultry-logo' data-aos="fade-left" data-aos-duration="700">
              <img className="ourBrandPoultry-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandPoultry-h1" data-aos="fade-left" data-aos-duration="500">
              Poultry
            </h2>
            <p className="ourBrandPoultry-p1" data-aos="fade-left" data-aos-duration="400">
                The leading poultry hatchery in Sri Lanka catering to needs 
                of poultry farmers around the island.
            </p>
          </div>
        </div>
      </center>
    </div>
  );
}

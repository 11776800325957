import React, { useEffect } from 'react';
import './ourBrandsFreshProduce.css';
import LogoImage from "../../images/Fresheez.png";
import FreshProduce from "../../images/ourBrandsFreshProduce.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsFreshProduce() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="ourBrandFreshProduce-home">
      <center>
        <div className="ourBrandFreshProduce-page-container">
          <div className="ourBrandFreshProduce-column ourBrandFreshProduce-image-columns">
              <div className="ourBrandFreshProduce-image-container" data-aos="fade-right" data-aos-duration="700">
                <img className="ourBrandFreshProduce-image2" alt="FreshProduce" src={FreshProduce} />
              </div>
          </div>
          <div className="ourBrandFreshProduce-column ourBrandFreshProduce-text-column">
            <div className='ourBrandFreshProduce-logo' data-aos="fade-left" data-aos-duration="700">
              <img className="ourBrandFreshProduce-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandFreshProduce-h1" data-aos="fade-left" data-aos-duration="500">
              Fresh Produce
            </h2>
            <p className="ourBrandFreshProduce-p1" data-aos="fade-left" data-aos-duration="400">
                The Country’s premier provider of fresh vegetables and fruits as well as 
                specialty condiments and other essentials delivered to the market through 
                CIC’s network of Fresheez outlets.
            </p>
          </div>
        </div>
      </center>
    </div>
  );
}

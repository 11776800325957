import React, { useEffect } from 'react';
import './ourBrandsPlantProtection.css';
import LogoImage from "../../images/WhiteLogo2.png";
import Clincher from "../../images/Img01.png";
import Loyant from "../../images/Img02.png";
import Radiant from "../../images/Img03.png";
import Transform from "../../images/Img04.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function OurBrandsPlantProtection() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const brands = [
    { imgSrc: Clincher },
    { imgSrc: Loyant },
    { imgSrc: Radiant },
    { imgSrc: Transform },
  ];

  return (
    <div className="ourBrandPlantProtection-home">
      <center>
        <div className="ourBrandPlantProtection-page-container">
          <div className="ourBrandPlantProtection-column ourBrandPlantProtection-image-columns">
            {brands.map((brand, index) => (
              <div key={index} className="ourBrandPlantProtection-image-container" data-aos="zoom-in" data-aos-duration="800">
                <img className="ourBrandPlantProtection-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
              </div>
            ))}
          </div>
          <div className="ourBrandPlantProtection-column ourBrandPlantProtection-text-column">
            <div className='ourBrandPlantProtection-logo' data-aos="fade-left" data-aos-duration="700">
              <img className="ourBrandPlantProtection-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandPlantProtection-h1" data-aos="fade-left" data-aos-duration="500">
              Plant Protection
            </h2>
            <p className="ourBrandPlantProtection-p1" data-aos="fade-left" data-aos-duration="400">
                Solutions for the protection of agricultural crops against insect and pest attacks.
            </p>
          </div>
        </div>
      </center>
    </div>
  );
}

import React from 'react';
import './ourBrandsIndustrial.css';
import LogoImage from "../../images/CICOurBrandsLogo.png";
import Chemanex from "../../images/Chemanex.png";
import CISCO from "../../images/CISCO.png";
import NalcoWater from "../../images/NalcoWater.png";
import Superbond from "../../images/Superbond.png";

export default function OurBrandsIndustrial() {

  const brands = [
    { imgSrc: Chemanex },
    { imgSrc: CISCO },
    { imgSrc: NalcoWater },
    { imgSrc: Superbond },
  ];

  return (
    <div className="ourBrandsIndustrial-home">
      <center>
        <div className="ourBrandsIndustrial-page-container">
          <div className="ourBrandsIndustrial-column ourBrandsIndustrial-image-columns">
            {brands.map((brand, index) => (
              <div key={index} className="ourBrandsIndustrial-image-container" data-aos="zoom-in" data-aos-duration="700">
                <img className="ourBrandsIndustrial-image2" alt={`Brand ${index + 1}`} src={brand.imgSrc} />
              </div>
            ))}
          </div>
          <div className="ourBrandsIndustrial-column ourBrandsIndustrial-text-column">
            <div className='ourBrandsIndustrial-logo' data-aos="fade-left" data-aos-duration="700">
              <img className="ourBrandsIndustrial-logo-image" alt="Logo" src={LogoImage} />
            </div>
            <h2 className="ourBrandsIndustrial-h1" data-aos="fade-left" data-aos-duration="500">
              Industrial Solutions
            </h2>
            <p className="ourBrandsIndustrial-p1" data-aos="fade-left" data-aos-duration="400">
                Solutions for a range of industries, including paint manufacture, apparel, beverage and packaging.
            </p>
          </div>
        </div>
      </center>
    </div>
  );
}
